import type { JobGroup, Paginated } from '@borg/types';

const key = 'featured-jobs';

export const useFeaturedJobsPage = () => useState<number>('use-featured-jobs-page', () => 1);

export function useFeaturedJobs() {
  const { data } = useNuxtData<Paginated<JobGroup>>(key);
  return data;
}

export function getFeaturedJobs() {
  const featuredJobsPage = useFeaturedJobsPage();
  const featuredJobs = useFeaturedJobs();

  return useAsyncData(key, () => jobsService.getFeaturedJobs({ page: featuredJobsPage.value }), {
    getCachedData: () => featuredJobs.value ?? null,
  });
}
