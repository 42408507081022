<template>
  <div class="homepage">
    <SiteLinks />
    <RootPositions />
    <FeaturedJobs />
  </div>
</template>

<style scoped lang="scss">
  .homepage {
    display: flex;
    flex-direction: column;
    gap: var(--mp-space-100);
  }
</style>
