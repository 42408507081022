<script setup lang="ts">
  import { MpLoadingContainer } from '@borg/ui';

  const { t } = useI18n();

  useHead({
    title: t('INDEX_PAGE.TITLE'),
    meta: [{ name: 'description', content: t('INDEX_PAGE.DESCRIPTION') }],
  });

  useSeoMeta({
    ogTitle: t('INDEX_PAGE.TITLE'),
    ogDescription: t('INDEX_PAGE.DESCRIPTION'),
  });
</script>

<template>
  <Suspense>
    <PageIndex />
    <template #fallback>
      <MpLoadingContainer is-loading />
    </template>
  </Suspense>
</template>
