<script setup lang="ts">
  import { MpButton, MpCard, MpText } from '@borg/ui';
  import { Position } from '@borg/types';

  const { data: positions } = await rootPositionsRepository.getMany();
  const showAllPositions = ref(false);
  const initialShowNumber = 4;

  const extendable = computed(
    () => (positions.value && positions.value.length > initialShowNumber) ?? false,
  );
  const showExtendButton = computed(() => !showAllPositions.value && extendable.value);
  const visiblePositions = computed(() =>
    positions.value
      ? extendable.value && !showAllPositions.value
        ? positions.value.slice(0, initialShowNumber)
        : positions.value
      : [],
  );

  function onSearchPosition(position: Position) {
    navigate({ name: 'search', query: { positions: position.id } });
  }

  function onDisplayAllPositions() {
    showAllPositions.value = true;
  }
</script>

<template>
  <div
    v-if="positions?.length"
    class="root-positions"
  >
    <MpText
      as="h2"
      no-margin
      class="root-positions__title"
    >
      {{ $t('ROOT_POSITIONS.TITLE') }}
    </MpText>
    <div class="root-positions__list">
      <TransitionGroup name="list">
        <MpCard
          v-for="position in visiblePositions"
          :key="position.id"
          p="lg"
          class="position"
          shadow
          border
          @click="onSearchPosition(position)"
        >
          <img
            v-if="position.imageUrl"
            class="position__image"
            :src="position.imageUrl"
            :alt="position.name"
          />
          <MpText
            type="h6"
            no-margin
            bold
          >
            {{ position.name }}
          </MpText>
        </MpCard>
      </TransitionGroup>
    </div>
    <MpButton
      v-if="showExtendButton"
      :text="$t('ROOT_POSITIONS.DISPLAY_ALL')"
      class="root-positions__display_all-button"
      color-scheme="primary"
      shape="oval"
      @click="onDisplayAllPositions"
    />
  </div>
</template>

<style scoped lang="scss">
  .root-positions {
    display: flex;
    flex-direction: column;
    gap: var(--mp-space-80);

    &__title {
      text-align: center;
    }

    &__list {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      gap: var(--mp-space-50);
    }

    &__display_all-button {
      align-self: center;
    }
  }

  .position {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: var(--mp-color-text-20);
    text-align: center;
    width: 254px;
    height: 254px;
    gap: var(--mp-space-50);

    &__image {
      max-width: 120px;
      max-height: 120px;
      width: auto;
      height: auto;
    }

    &:hover {
      box-shadow: var(--mp-shadow-medium);
    }
  }

  .list-enter-active,
  .list-leave-active {
    transition: all 0.5s ease-out;
  }

  .list-enter-from,
  .list-leave-to {
    opacity: 0;
    transform: translateY(-30px);
  }
</style>
