<script setup lang="ts">
  import { MpInfiniteScroll } from '@borg/ui';

  await getFeaturedJobs();

  const data = useFeaturedJobs();
  const page = useFeaturedJobsPage();
  const pending = ref(false);

  async function loadMore() {
    if (pending.value || (data.value?.totalPages && page.value >= data.value.totalPages)) {
      return;
    }
    pending.value = true;
    page.value += 1;
    const nextData = await jobsService.getFeaturedJobs({ page: page.value });
    if (data.value) {
      data.value = {
        ...data.value,
        items: [...data.value.items, ...nextData.items],
      };
    }
    pending.value = false;
  }
</script>

<template>
  <HomepageJobsSection v-if="!!data?.items?.length">
    <MpInfiniteScroll
      :distance="800"
      :is-loading="pending"
      @near-end="loadMore"
    >
      <Grid columns="two">
        <JobCardV5
          v-for="item in data.items"
          :key="item.id"
          :item="item"
          source="homepage_featured"
        />
      </Grid>
    </MpInfiniteScroll>
  </HomepageJobsSection>
</template>
