<script setup lang="ts">
  import { MpButton, MpText } from '@borg/ui';

  const { data: links } = await siteLinksRepository.getMany();
</script>

<template>
  <div
    v-if="links?.length"
    class="hot-tags"
  >
    <MpText
      as="h2"
      class="hot-tags__title"
    >
      {{ $t('HOT_TAGS.TITLE') }}
    </MpText>
    <div class="hot-tags__list">
      <LinksLocale
        v-for="link in links"
        :key="link.url"
        :to="link.url"
        :target="link.url[0] === '/' ? undefined : '_blank'"
      >
        <MpButton
          class="hot-tags__tag"
          :text="link.title"
          left-icon="magnifier"
          color-scheme="secondary"
          shape="oval"
        />
      </LinksLocale>
    </div>
  </div>
</template>

<style scoped lang="scss">
  .hot-tags {
    justify-content: center;

    &__title {
      text-align: center;
      margin-bottom: var(--mp-space-80);
    }

    &__list {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      gap: var(--mp-space-50);
    }

    &__tag {
      --button-color: var(--mp-color-secondary-dark);
    }
  }
</style>
